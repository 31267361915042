import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getSafe, getContentfulUrl } from '../shared/utilities'
import TableRowField from '../components/TableRowField'

const Professional = ({ pageContext, location }) => {
  const {
    id,
    name,
    state,
    categories,
    qualifications,
    crossCulturalExperience,
    contactEmail,
    contactPhone,
    contactUrl,
    workPlace,
    profileImage,
    profession
  } = pageContext

  const imgQuery = '?w=200&h=200'
  const imgUrl = getContentfulUrl(profileImage) + imgQuery
  const crossCultural = getSafe(() => crossCulturalExperience.crossCulturalExperience, null)
  const contactEmailLink = contactEmail ? (
    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
  ) : null
  const contactUrlLink = contactUrl ? (
    <a target="_blank" href={contactUrl} rel="noopener noreferrer">
      {contactUrl}
    </a>
  ) : null
  const qualificationDisplay = getSafe(() => qualifications.qualifications, null)

  return (
    <Layout path={location.pathname}>
      <SEO title={name} />
      <div className="container professional text-content">
        <Link to="/resources/helping-professionals" className="back-link">
          &larr; Helping Professionals
        </Link>
        <h1>{name}</h1>
        <img height="200px" src={imgUrl} alt={name} />
        <table>
          <tbody>
            <TableRowField label="State" data={state} />
            <TableRowField label="Profession" data={profession} />
            <TableRowField label="Qualifications" data={qualificationDisplay} />
            <TableRowField label="Cross Cultural Experience" data={crossCultural} />
            <TableRowField label="Workplace" data={workPlace} />
            <TableRowField label="Email" data={contactEmailLink} />
            <TableRowField label="Phone" data={contactPhone} />
            <TableRowField label="Website" data={contactUrlLink} />
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default Professional
