import React from 'react'

const TableRowField = ({ label, data }) => {
  if (!data) return null

  return (
    <tr>
      <td style={{ color: '#777' }}>{label}</td>
      <td>{data}</td>
    </tr>
  )
}

export default TableRowField
